import React from 'react'
import Layout from '../components/Layout/Layout'
import Footer from '../components/Footer'
import '../css/main.css'
function Policies() {
    return (
        <div>

            <Layout>
                <section class="section faq fade-wrapper m-3 ">
                    <div class="section-title" style={{marginTop:"-10px"}}>
                        <h2>Our Policies<br></br></h2>
                        <p><span>Learn More</span> <span class="description-title">About Our Policies</span></p>
                    </div>

                    <div class="">
                        <div class="row gaper">
                            <div className="col-lg-1"></div>
                            <div class="col-12 col-lg-10">
                                <div class="accordion" id="accordion">
                                    <div class="accordion-item fade-top">
                                        <h5 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Privacy Policy
                                            </button>
                                        </h5>
                                        <div id="collapseOne" class="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordion">
                                            <div class="accordion-body">
                                                <div class="accordion-body">
                                                    <h6 class='mt-3'>Privacy Policy</h6>

                                                    <p>Welcome to Orddar, owned and operated by REVZCON NETWORK PRIVATE LIMITED ("Orddar," "we," "us," "our"). This Privacy Policy ("Policy") governs the collection, usage, disclosure, and protection of your information when you use the Orddar mobile application ("Orddar App") and related services offered in India, specifically focusing on food, grocery, and bakery delivery services.</p>

                                                    <b>1. Consent:</b>
                                                    <p>By accessing or using the Orddar App and its services, you consent to the collection, transfer, usage, storage, disclosure, and sharing of your information as outlined in this Policy. If you do not agree with this Policy, please refrain from using the Orddar App.</p>

                                                    <b>2. Policy Updates:</b>
                                                    <p>We reserve the right to update this Policy periodically to reflect changes in our practices or applicable laws. Any material changes will be notified to you through the Orddar App or via contact@orddar.com. Your continued use of the Orddar App after such changes constitutes your acceptance of the updated Policy.</p>

                                                    <b>3. Information Collection:</b>
                                                    <p>We collect various types of information to provide, personalize, maintain, and enhance our products and services. This includes:</p>
                                                    <ul>
                                                        <li><b>Device Information:</b> We may collect information about the devices you use to access the Orddar App, including device identifiers, hardware models, operating systems, and network information.</li>
                                                        <li><b>Usage Data:</b> We gather data on how you interact with the Orddar App, such as your preferences, actions, and usage patterns.</li>
                                                        <li><b>Voluntary Information:</b> You may choose to provide us with additional information, such as your name, contact details, delivery addresses, and payment information, to use certain features of the Orddar App.</li>
                                                    </ul>

                                                    <b>4. Cookies:</b>
                                                    <p>We use cookies and similar technologies to enhance your browsing experience, personalize content, and analyze usage patterns. By using the Orddar App, you consent to our use of cookies as described in this Policy.</p>

                                                    <b>5. Information Usage:</b>
                                                    <p>We use the collected information for the following purposes:</p>
                                                    <ul>
                                                        <li>To provide, personalize, maintain, and improve our products and services.</li>
                                                        <li>To administer security measures and protect the integrity of the Orddar App.</li>
                                                        <li>To deliver relevant advertisements and promotional offers.</li>
                                                        <li>To comply with legal obligations and enforce our Terms of Service.</li>
                                                    </ul>

                                                    <b>6. Information Sharing:</b>
                                                    <p>We may share your information with the following parties:</p>
                                                    <ul>
                                                        <li><b>Service Providers:</b> We may engage third-party service providers to assist us in delivering our services, such as payment processors, delivery partners, and analytics providers.</li>
                                                        <li><b>Partner Merchants:</b> Your information may be shared with partner restaurants, grocery stores, and bakeries to facilitate order processing and delivery.</li>
                                                        <li><b>Academic Partners:</b> We may collaborate with academic institutions for research purposes, provided that your information is anonymized and aggregated.</li>
                                                        <li><b>Other Users:</b> If you are a delivery partner, we may share certain information with other users to fulfill delivery services.</li>
                                                        <li><b>Governmental Agencies:</b> We may disclose information to comply with legal requirements or respond to lawful requests from governmental authorities.</li>
                                                        <li><b>Advertising Purposes:</b> We may use your information to deliver targeted advertisements and promotional offers within the Orddar App and on third-party platforms. These advertisements may be based on your usage patterns, preferences, and interactions with the Orddar App.</li>
                                                        <li><b>Internal Usage:</b> We may analyze the collected data to understand user behavior, usage patterns, and trends. This analysis helps us improve the Orddar App’s features, functionalities, and overall user experience. Additionally, we may use the information for internal reporting, research, and analytics to optimize our services and offerings.</li>
                                                    </ul>

                                                    <b>7. Data Security:</b>
                                                    <p>We employ industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.</p>

                                                    <b>8. Opt-Out:</b>
                                                    <p>You have the right to manage your communication preferences and opt out of receiving certain types of communications from us. However, please note that certain administrative, service, or legal notices may still be transmitted to you.</p>

                                                    <b>9. Grievance Officer:</b>
                                                    <p>If you have any questions, concerns, or grievances regarding your information or this Policy, please contact our Grievance Officer at contact@revzcon.com.</p>

                                                    <b>10. Policy Changes:</b>
                                                    <p>This Policy may be updated from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes through the Orddar App or via contact@orddar.com.</p>

                                                    <p>Please review this Policy periodically for updates. Your continued use of the Orddar App after such changes constitutes your acceptance of the updated Policy.</p>
                                                </div>


                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item fade-top">
                                        <h5 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Terms & Conditions
                                            </button>
                                        </h5>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordion">
                                            <div class="accordion-body">
                                                <h6 class='mt-3'>Terms and Conditions</h6>

                                                <b>1. Introduction</b>
                                                <p>Welcome to Orddar, the food, grocery, and bakery delivery application operated by REVZCON NETWORK PRIVATE LIMITED ("Company"). These terms and conditions ("Terms") govern your use of the Orddar application ("App"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the App.</p>

                                                <b>2. Eligibility and Account Registration</b>
                                                <p><b>2.1 Eligibility:</b> You must be at least 18 years old to use the App. By using the App, you represent and warrant that you are at least 18 years old.</p>
                                                <p><b>2.2 Account Registration:</b> To access certain features of the App, you may be required to register an account. You agree to provide accurate and complete information when registering an account with the App.</p>
                                                <p><b>2.3 Account Security:</b> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                                                <b>3. Services Provided</b>
                                                <p><b>3.1 Description of Services:</b> The Orddar App provides a convenient platform for users to order a variety of food, grocery, and bakery items from participating restaurants, grocery stores, and bakeries ("Merchants"). Users can browse through menus, place orders, and make payments directly through the App.</p>
                                                <p><b>3.2 Merchant Relationships:</b> Orddar acts as an intermediary between users and Merchants, facilitating the ordering and delivery process. While Orddar strives to ensure the quality and accuracy of the services provided by Merchants, it does not own, operate, or control them. Therefore, Orddar cannot guarantee the quality, safety, or legality of the products offered by Merchants. Any issues or disputes regarding the products or services provided by Merchants should be resolved directly with the respective Merchant.</p>
                                                <p><b>3.3 Delivery Process:</b> Orddar primarily relies on participating restaurants to fulfill and deliver orders placed through the App. Delivery times may vary depending on the availability of the Merchant and the user's location. While Orddar endeavors to provide accurate delivery estimates, it cannot guarantee specific delivery times due to factors beyond its control, such as traffic conditions and weather.</p>

                                                <b>4. Ordering and Payment</b>
                                                <p><b>4.1 Placing Orders:</b> Users can place orders through the Orddar App by selecting items from the menu provided by the participating Merchant. Once an order is placed, users agree to pay the total amount specified for the order, including any applicable taxes, fees, and delivery charges.</p>
                                                <p><b>4.2 Payment Methods:</b> Payment for orders can be made using various methods, including UPI, credit card, debit card, net banking, and cash on delivery (where available).</p>
                                                <p><b>4.3 Order Acceptance:</b> Orddar reserves the right to refuse or cancel any order at any time for any reason. In the event that an order is cancelled, users will be notified and any payment made for the cancelled order will be refunded.</p>

                                                <b>5. Intellectual Property</b>
                                                <p><b>5.1 Ownership:</b> The Orddar App and all content and materials available on the App are owned by REVZCON NETWORK PRIVATE LIMITED or its licensors and are protected by intellectual property laws.</p>
                                                <p><b>5.2 Restrictions:</b> Users may not reproduce, distribute, modify, or create derivative works of any content from the Orddar App without prior written consent from REVZCON NETWORK PRIVATE LIMITED.</p>

                                                <b>6. Limitation of Liability</b>
                                                <p><b>6.1 Disclaimer of Damages:</b> To the fullest extent permitted by law, Orddar shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the App.</p>
                                                <p><b>6.2 Maximum Liability:</b> Orddar's total liability, whether in contract, warranty, tort (including negligence), or otherwise, shall not exceed the amount paid by the user for the services provided through the App.</p>

                                                <b>7. Governing Law and Jurisdiction</b>
                                                <p><b>7.1 Applicable Law:</b> These Terms shall be governed by and construed in accordance with the laws of India.</p>
                                                <p><b>7.2 Jurisdiction:</b> Any disputes arising out of or in connection with these Terms or your use of the App shall be subject to the exclusive jurisdiction of the court located in Pathanamthitta.</p>

                                                <b>8. Privacy</b>
                                                <p>Your use of the Orddar app is subject to our Privacy Policy, which describes how we collect, use, and protect your personal information. By using the app, you consent to the collection and use of your information as described in the Privacy Policy.</p>

                                                <b>9. Termination</b>
                                                <p>Orddar reserves the right to suspend or terminate your access to the app at any time for any reason, including but not limited to violation of these Terms or misuse of the app.</p>

                                                <b>10. Contact Information</b>
                                                <p>For any questions or concerns regarding these Terms or the Orddar App, users may contact REVZCON NETWORK PRIVATE LIMITED at contact@revzcon.com.</p>

                                                <b>11. Acceptance of Terms</b>
                                                <p>By accessing or using the Orddar App, users agree to be bound by these Terms. If users do not agree to these Terms, they may not use the App.</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="accordion-item content__space fade-top">
                                        <h5 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">
                                                Cookie Policy
                                            </button>
                                        </h5>
                                        <div id="collapseThree" class="accordion-collapse collapse"
                                            aria-labelledby="headingThree" data-bs-parent="#accordion">
                                            <div class="accordion-body">
                                                <h6 className='mt-3'>Cookie Policy</h6>


                                                <p>  This website uses cookies to improve the user experience and ensure it functions effectively, and
                                                    occasionally to provide marketing information or advertise online, either directly or through third party vendors.</p>
                                                <p>
                                                    This Cookie Policy is part of REVZCON NETWORK PRIVATE LIMITED ("Orddar") Privacy Policy.
                                                    For more information about Orddar and how it protects your information, please see the Privacy
                                                    Policy.
                                                </p>
                                                <p>
                                                    To provide you with a more personalized and responsive service, Orddar needs to remember and
                                                    store information about how you use this website. This is done using small text files called
                                                    cookies. Cookies contain small amounts of information and are downloaded to your computer or
                                                    other device by a server for this website. Your web browser then sends these cookies back to this
                                                    website on each subsequent visit so that it can recognize you and remember things like your user
                                                    preferences. You can find more detailed information about cookies and how they work at https://
                                                    www.aboutcookies.org.
                                                </p>
                                                <p>
                                                    Whenever you use this website, information may be collected using cookies and other
                                                    technologies. By using this website, you agree to Orddar's use of cookies as described in this
                                                    Cookie Policy and also to the use of cookies on the other country, regional, or practice-specific
                                                    websites contained in orddar.com that you may visit, as described in their accompanying cookie
                                                    policy.
                                                </p>
                                                <b>What cookies does Orddar use and why?</b>
                                                <p>
                                                    Following cookies may be used at Orddar:
                                                </p>

                                                <ul>
                                                    <li>Essential cookies

                                                    </li>
                                                    <li>Performance cookies</li>
                                                    <li>Functionality cookies</li>
                                                    <li>Behaviorally Targeted Advertising cookies</li>
                                                    <li>Compliance, Security Authentication Cookies</li>
                                                    <li>Notification Cookies</li>
                                                </ul>


                                                <p>
                                                    Orddar or its service providers also use analytic services to understand how effective Orddar's
                                                    content is, what interests Orddar's users have, and to improve how this website works. In
                                                    addition, Orddar uses web beacons, or tracking pixels to count visitor numbers and performance
                                                    cookies to track how many individual users access this website and how often. This information is
                                                    used for statistical purposes only, and it is not Orddar's intention to use such information to
                                                    personally identify any user. However, if you have registered and signed into this website, Orddar
                                                    may combine this information with information from its web analytic services and cookies to
                                                    analyze how you use this website in more detail.
                                                </p>
                                                <p> Please contact Orddar through any of the means highlighted in our Privacy Policy if you would like
                                                    more detailed information on the cookies it uses.</p>
                                                <b>How to Control Cookies?</b>
                                                <p> By using this website, you agree that Orddar can place cookies on your computer or device as
                                                    explained above. However, you can control and manage cookies in various ways. Please bear in
                                                    mind that removing or blocking cookies can impact user experience and parts of this website may
                                                    no longer be fully accessible.</p>


                                                <ul>
                                                    <li>Browser Controls</li>
                                                    <li>
                                                        Managing Analytics Cookies
                                                    </li>
                                                    <li>Managing Local Shared Objects or Flash Cookies</li>
                                                </ul>


                                                <b>  Social Buttons and/or External Web Services</b>
                                                <p>The website contains 'social buttons' that enable users to share or bookmark web pages. These
                                                    are buttons for third-party social media sites, and these sites may log information about your
                                                    activities on the Internet, including on this website. Orddar sometimes also uses external web
                                                    services on this website to display content within the web pages of this website, for example, to
                                                    display images, show videos, or run polls. As with the social buttons, Orddar cannot prevent
                                                    these sites, or external domains, from collecting information on your use of this embedded
                                                    content. Please review the respective terms of use and privacy policies/statements/notices of
                                                    these sites or external domains to understand exactly how they use your information and to find
                                                    out how to opt-out, or delete, such information.</p>
                                                <b>  Amendment to the policy</b>
                                                <p>         Further, Orddar may modify or amend this Cookie Policy from time to time at its discretion. When
                                                    Orddar makes changes to this policy, it will amend the revision date at the top of this page, and
                                                    such modified or amended Cookie Policy shall be effective to you and your information as of that
                                                    revision date. Orddar encourages you to periodically review this Cookie Policy to be informed
                                                    about how it is using cookies.</p>
                                                <b>    Contact: contact@orddar.com</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item content__space fade-top">
                                        <h5 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour" aria-expanded="false"
                                                aria-controls="collapseFour">
                                                User Agreement
                                            </button>
                                        </h5>
                                        <div id="collapseFour" class="accordion-collapse collapse"
                                            aria-labelledby="headingFour" data-bs-parent="#accordion">
                                            <div class="accordion-body">
                                                <h6 class='mt-3'>User Agreement</h6>

                                                <p>This User Agreement ("Agreement") governs your use of the Orddar food, grocery, and bakery delivery application ("Orddar," "app," "platform") provided by REVZCON NETWORK PRIVATE LIMITED ("Orddar," "we," "us," or "our"). By accessing or using the Orddar app, you agree to be bound by the terms and conditions of this Agreement.</p>

                                                <b>1. Account Registration</b>
                                                <p>To use certain features of the Orddar app, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                                                <b>2. Use of the App</b>
                                                <p>You agree to use the Orddar app only for lawful purposes and in compliance with all applicable laws and regulations. You may not use the app to engage in any fraudulent, illegal, or unauthorized activities, including but not limited to unauthorized access to the app or its content, interference with the app's operation, or violation of third-party rights.</p>

                                                <b>3. Ordering and Purchases</b>
                                                <p>By placing an order through the Orddar app, you agree to pay the specified price for the products or services ordered, including any applicable taxes and fees. You acknowledge that Orddar acts as a platform to facilitate transactions between you and third-party vendors, and Orddar is not responsible for the quality, accuracy, or legality of the products or services provided by vendors.</p>

                                                <b>4. Intellectual Property</b>
                                                <p>The Orddar app and its content, including but not limited to text, graphics, logos, images, software, and trademarks, are owned by Orddar or its licensors and are protected by intellectual property laws. You may not reproduce, modify, distribute, or publicly display any part of the app without prior written permission from Orddar.</p>

                                                <b>5. Privacy</b>
                                                <p>Your use of the Orddar app is subject to our Privacy Policy, which describes how we collect, use, and protect your personal information. By using the app, you consent to the collection and use of your information as described in the Privacy Policy.</p>

                                                <b>6. Termination</b>
                                                <p>Orddar reserves the right to suspend or terminate your access to the app at any time for any reason, including but not limited to violation of this Agreement or misuse of the app. Upon termination, you must cease all use of the app and delete any downloaded or stored content.</p>

                                                <b>7. Disclaimer of Warranties</b>
                                                <p>THE ORDDAR APP IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. ORDDAR DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>

                                                <b>8. Limitation of Liability</b>
                                                <p>IN NO EVENT SHALL ORDDAR BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE ORDDAR APP, WHETHER OR NOT ORDDAR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

                                                <b>9. Governing Law</b>
                                                <p>This Agreement shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts located in [insert jurisdiction].</p>

                                                <b>10. Contact Us</b>
                                                <p>If you have any questions or concerns about this Agreement or the Orddar app, please contact us at contact@orddar.com.</p>

                                                <p>By using the Orddar app, you acknowledge that you have read, understood, and agreed to be bound by the terms and conditions of this Agreement.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item content__space fade-top">
                                        <h5 class="accordion-header" id="headingFive">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFive" aria-expanded="false"
                                                aria-controls="collapseFive">
                                                Refund Policy
                                            </button>
                                        </h5>
                                        <div id="collapseFive" class="accordion-collapse collapse"
                                            aria-labelledby="headingFive" data-bs-parent="#accordion">
                                            <div class="accordion-body">
                                                <h6 class='mt-3'>Refund Policy</h6>

                                                <p>This Refund Policy ("Policy") applies to purchases made through the Orddar food grocery and bakery delivery application ("Orddar," "app," "platform") provided by REVZCON NETWORK PRIVATE LIMITED ("Orddar," "we," "us," or "our"). By using the Orddar app, you agree to be bound by the terms of this Policy.</p>

                                                <b>1. Eligibility for Refunds</b>
                                                <p>Refunds may be issued for the following reasons:</p>
                                                <ul>
                                                    <li>Incorrect or incomplete delivery of ordered items.</li>
                                                    <li>Quality issues with the delivered products, such as spoilage or damage.</li>
                                                    <li>Unauthorized charges on your account.</li>
                                                </ul>

                                                <b>2. Refund Process</b>
                                                <p>To request a refund, you must contact Orddar customer support at contact@orddar.com within 12 hours of receiving the delivery. You must provide details of the issue, including your order number and relevant evidence, such as photographs of the delivered items.</p>
                                                <p>Upon receipt of your refund request, Orddar will investigate the issue and determine whether a refund is warranted. If a refund is approved, it will be processed within 5-7 business days to the original payment method used for the purchase.</p>

                                                <b>3. Exceptions</b>
                                                <p>Refunds may not be issued in the following circumstances:</p>
                                                <ul>
                                                    <li>Change of mind or preference after the order has been placed.</li>
                                                    <li>Delays in delivery due to factors beyond Orddar's control, such as weather conditions or traffic congestion.</li>
                                                    <li>Inability to deliver certain items due to unavailability or discontinuation by the vendor.</li>
                                                </ul>

                                                <b>4. Contact Us</b>
                                                <p>If you have any questions or concerns about our refund policy or need assistance with a refund request, please contact Orddar customer support at contact@orddar.com.</p>

                                                <p>By using the Orddar app, you acknowledge that you have read, understood, and agreed to be bound by this Refund Policy. Orddar reserves the right to amend or update this Policy at any time without prior notice.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item content__space fade-top">
                                        <h5 class="accordion-header" id="headingSix">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseSix" aria-expanded="false"
                                                aria-controls="collapseSix">
                                                Vendor Agreement
                                            </button>
                                        </h5>
                                        <div id="collapseSix" class="accordion-collapse collapse"
                                            aria-labelledby="headingSix" data-bs-parent="#accordion">
                                            <div class="accordion-body">
                                                <h6 class='mt-3'>Vendor Agreement</h6>

                                                <p>This Vendor Agreement ("Agreement") is entered into by and between [Vendor Name] ("Vendor") and REVZCON NETWORK PRIVATE LIMITED ("Company"), owner of the Orddar application ("App"). By clicking the "Accept" button below, Vendor agrees to the following terms and conditions:</p>

                                                <b>1. Services:</b>
                                                <p>Vendor agrees to list and offer food, grocery, and bakery products for delivery through the Orddar application.</p>

                                                <b>2. Terms of Service:</b>
                                                <p>Vendor agrees to adhere to the terms of service outlined by the Company, as well as any additional terms specific to Vendor's use of the Orddar platform.</p>

                                                <b>3. Product Listing and Pricing:</b>
                                                <p>Vendor is responsible for accurately listing products, including descriptions, images, and prices, on the Orddar platform. Vendor agrees to maintain up-to-date product information and pricing.</p>

                                                <b>4. Order Fulfillment:</b>
                                                <p>Vendor agrees to promptly fulfill orders placed through the Orddar platform. Vendor will provide estimated preparation and delivery times for each order.</p>

                                                <b>5. Delivery:</b>
                                                <p>Vendor is responsible for packaging products securely and delivering them to customers within the designated delivery timeframe. Vendor must ensure that delivered products meet quality and hygiene standards.</p>

                                                <b>6. Payments:</b>
                                                <p>Company will collect payments from customers on behalf of Vendor and remit payments to Vendor according to the agreed-upon payment terms.</p>

                                                <b>7. Compliance with Laws:</b>
                                                <p>Vendor agrees to comply with all applicable laws, regulations, and licensing requirements related to the sale and delivery of food, grocery, and bakery products.</p>

                                                <b>8. Intellectual Property:</b>
                                                <p>Vendor grants Company the right to use Vendor's name, logo, and product images for the purpose of promoting Vendor's products on the Orddar platform.</p>

                                                <b>9. Confidentiality:</b>
                                                <p>Both parties agree to keep confidential any proprietary or sensitive information shared during the course of their business relationship.</p>

                                                <b>10. Termination:</b>
                                                <p>Either party may terminate this Agreement with written notice to the other party. Upon termination, Vendor agrees to fulfill any outstanding orders and settle any outstanding payments.</p>

                                                <b>11. Governing Law:</b>
                                                <p>This Agreement shall be governed by and construed in accordance with the laws of India.</p>

                                                <p>By clicking "Accept," Vendor acknowledges that they have read, understood, and agreed to the terms and conditions outlined in this Agreement.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item content__space fade-top">
                                        <h5 class="accordion-header" id="headingSeven">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseSeven" aria-expanded="false"
                                                aria-controls="collapseSeven">
                                                Restaurant Agreement
                                            </button>
                                        </h5>
                                        <div id="collapseSeven" class="accordion-collapse collapse"
                                            aria-labelledby="headingSeven" data-bs-parent="#accordion">
                                            <div class="accordion-body">
                                                <h6 class='mt-3'>Restaurant Agreement</h6>

                                                <p>This Restaurant Agreement ("Agreement") is entered into by and between [Restaurant Name] ("Restaurant") and REVZCON NETWORK PRIVATE LIMITED ("Company"), owner of the Orddar application ("App"). By clicking the "Accept" button below, Restaurant agrees to the following terms and conditions:</p>

                                                <b>1. Listing of Products:</b>
                                                <p>Restaurant agrees to list and offer food, grocery, and bakery products for delivery through the Orddar application.</p>

                                                <b>2. Product Information:</b>
                                                <p>Restaurant is responsible for providing accurate descriptions, images, and prices for listed products. Restaurant agrees to maintain up-to-date product information and pricing on the Orddar platform.</p>

                                                <b>3. Order Fulfillment:</b>
                                                <p>Restaurant agrees to promptly fulfill orders placed through the Orddar platform. Restaurant will provide estimated preparation and delivery times for each order.</p>

                                                <b>4. Delivery:</b>
                                                <p>Restaurant is responsible for packaging products securely and delivering them to customers within the designated delivery timeframe. Restaurant must ensure that delivered products meet quality and hygiene standards.</p>

                                                <b>5. Payments:</b>
                                                <p>Company will collect payments from customers on behalf of Restaurant and remit payments to Restaurant according to the agreed-upon payment terms.</p>

                                                <b>6. Compliance with Laws:</b>
                                                <p>Restaurant agrees to comply with all applicable laws, regulations, and licensing requirements related to the sale and delivery of food, grocery, and bakery products.</p>

                                                <b>7. Intellectual Property:</b>
                                                <p>Restaurant grants Company the right to use Restaurant's name, logo, and product images for the purpose of promoting Restaurant's products on the Orddar platform.</p>

                                                <b>8. Confidentiality:</b>
                                                <p>Both parties agree to keep confidential any proprietary or sensitive information shared during the course of their business relationship.</p>

                                                <b>9. Termination:</b>
                                                <p>Either party may terminate this Agreement with written notice to the other party. Upon termination, Restaurant agrees to fulfill any outstanding orders and settle any outstanding payments.</p>

                                                <b>10. Governing Law:</b>
                                                <p>This Agreement shall be governed by and construed in accordance with the laws of India.</p>

                                                <p>By clicking "Accept," Restaurant acknowledges that they have read, understood, and agreed to the terms and conditions outlined in this Agreement.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item content__space fade-top">
                                        <h5 class="accordion-header" id="headingEight">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseEight" aria-expanded="false"
                                                aria-controls="collapseEight">
                                                Orddar Q&A
                                            </button>
                                        </h5>
                                        <div id="collapseEight" class="accordion-collapse collapse"
                                            aria-labelledby="headingEight" data-bs-parent="#accordion">
                                            <div class="accordion-body">
                                                <h6 class='mt-3'>Account and Registration</h6>

                                                <b>1. How do I create an account on the Orddar app?</b>
                                                <p>To create an account on Orddar, follow the on-screen instructions to register. You'll need to provide your name, email address, phone number, and create a password.</p>

                                                <b>2. What information do I need to provide to register an account?</b>
                                                <p>You need to provide your name, email address, phone number, and create a password. Additional information such as your delivery address is also required.</p>

                                                <b>3. How do I reset my password if I forget it?</b>
                                                <p>If you forget your password, go to the login screen and click on "Forgot Password." Follow the instructions to reset your password using your registered email address or phone number.</p>

                                                <b>4. Can I update my personal information on the app?</b>
                                                <p>Yes, you can update your personal information by going to the "Account" or "Profile" section within the app and editing the relevant details.</p>

                                                <b>5. Is my personal information safe on Orddar?</b>
                                                <p>Yes, Orddar uses industry-standard security measures to protect your personal information. Please refer to our Privacy Policy for more details on how we handle your data.</p>

                                                <b>6. Can I save multiple addresses in my Orddar account?</b>
                                                <p>Yes, you can save multiple delivery addresses in your Orddar account. Go to the "Profile" section and add or edit your saved addresses.</p>

                                                <h6 class='mt-3'>Ordering</h6>

                                                <b>7. How do I place an order on the Orddar app?</b>
                                                <p>To place an order, open the Orddar app, browse through the available restaurants and products, add your desired items to the cart, and proceed to checkout. Follow the prompts to complete your order.</p>

                                                <b>8. Can I modify or cancel my order after placing it?</b>
                                                <p>Once an order is placed, modifications or cancellations depend on the restaurant's policies. Please contact the restaurant directly for assistance with changes or cancellations.</p>

                                                <b>9. How do I know if my order has been accepted?</b>
                                                <p>You will receive a confirmation notification within the app once your order has been accepted by the restaurant. You can also check the order status in the "Orders" section of the app.</p>

                                                <b>10. Can I reorder my favourite meals on Orddar?</b>
                                                <p>Yes, you can easily reorder your favourite meals by going to the "Orders" section and selecting the "Reorder" option for any past order.</p>

                                                <b>11. What should I do if I receive the wrong order?</b>
                                                <p>If you receive the wrong order, please contact the restaurant immediately using the contact details provided in the app. You can also report the issue through the app's support section.</p>

                                                <b>12. How do I rate and review a restaurant or a delivery?</b>
                                                <p>After your order is delivered, you can rate and review the restaurant and delivery service through the app. Go to the "Orders" section, select the completed order, and provide your feedback.</p>

                                                <b>13. Can I schedule an order for a later time?</b>
                                                <p>Some restaurants may offer the option to schedule orders for a later time. Check the restaurant's menu page for scheduling options when placing your order.</p>

                                                <b>14. Is there a minimum order value on Orddar?</b>
                                                <p>Minimum order values may vary depending on the restaurant. Please check the restaurant's menu page for any minimum order requirements.</p>

                                                <h6 class='mt-3'>Delivery</h6>

                                                <b>15. How does the delivery process work?</b>
                                                <p>Once your order is placed and accepted, the restaurant prepares your order and arranges for delivery. The delivery is managed by the restaurant, and you will receive updates on the status through the app.</p>

                                                <b>16. Who is responsible for delivering my order?</b>
                                                <p>Delivery is managed by the restaurant from which you placed the order. For delivery updates, please contact the restaurant directly.</p>

                                                <b>17. Can I track my order in real-time?</b>
                                                <p>Order tracking availability depends on the restaurant's capabilities. Please check the order status in the app for real-time updates or contact the restaurant directly for more information.</p>

                                                <b>18. What should I do if my order is late?</b>
                                                <p>If your order is late, please contact the restaurant directly using the contact details provided in the app for delivery updates and resolution.</p>

                                                <b>19. What happens if no one is home to receive the delivery?</b>
                                                <p>If no one is available to receive the delivery, the delivery person may attempt to contact you. Please ensure you are reachable at the contact number provided during the order placement.</p>

                                                <b>20. How do I contact the restaurant for delivery updates?</b>
                                                <p>Use the contact details provided in the app for the restaurant to get delivery updates directly from them.</p>

                                                <h6 class='mt-3'>Payments</h6>

                                                <b>21. What payment methods are accepted on the Orddar app?</b>
                                                <p>Orddar accepts payments via BHIM UPI apps, Debit Cards, Credit Cards, Net Banking, and PhonePe Wallet.</p>

                                                <b>22. How do I add or update my payment method?</b>
                                                <p>You can add or update your payment methods in the "Payment" section of the app. Follow the instructions to add a new card or update your existing payment information.</p>

                                                <b>23. Is it safe to use my credit/debit card on Orddar?</b>
                                                <p>Yes, Orddar uses secure payment gateways and encryption to ensure the safety of your payment information.</p>

                                                <b>24. How do I know if my payment was successful?</b>
                                                <p>After completing your payment, you will receive a confirmation notification in the app. You can also check the status of your payment in the "Orders" section.</p>

                                                <b>25. Can I pay with cash on delivery?</b>
                                                <p>Cash on delivery is available for most of our restaurants or orders but may not be available for certain restaurants due to their specific policies.</p>

                                                <b>26. Are there any additional charges for delivery?</b>
                                                <p>Delivery charges, if any, will be displayed during the checkout process. These charges may vary depending on the restaurant and location.</p>

                                                <b>27. Are there any additional fees for using the Orddar app?</b>
                                                <p>The prices listed in the app include all applicable fees and taxes. However, some restaurants may charge a delivery fee, which will be displayed at checkout.</p>

                                                <h6 class='mt-3'>Returns and Refunds</h6>

                                                <b>28. How do I request a refund for my order?</b>
                                                <p>To request a refund, please contact the restaurant directly and explain the issue. If you need further assistance, you can also reach out to Orddar support through the app.</p>

                                                <b>29. What is the refund policy on Orddar?</b>
                                                <p>Refund policies may vary depending on the restaurant. Please refer to the restaurant's refund policy or the Orddar refund policy, or contact the restaurant or Orddar support directly for details.</p>

                                                <b>30. How long does it take to process a refund?</b>
                                                <p>Refund processing times vary depending on the restaurant and payment method used. Please check with the restaurant for specific timelines or the Orddar refund policy.</p>

                                                <b>31. What should I do if I receive a damaged or incorrect item?</b>
                                                <p>If you receive a damaged or incorrect item, please contact the restaurant immediately to report the issue and request a resolution.</p>

                                                <h6 class='mt-3'>Account Issues</h6>

                                                <b>32. How do I delete my Orddar account?</b>
                                                <p>To delete your Orddar account, please use the delete option in the app or contact Orddar support at support@orddar.com with your request.</p>

                                                <b>33. What should I do if I suspect unauthorised activity on my account?</b>
                                                <p>If you suspect unauthorised activity on your account, immediately change your password and contact Orddar support for further assistance.</p>

                                                <b>34. How do I contact customer support for account-related issues?</b>
                                                <p>You can contact Orddar customer support through the "Help & Support" section in the app or by emailing support@orddar.com.</p>

                                                <b>35. How can I update my account information on Orddar?</b>
                                                <p>To update your account information, go to the "Profile" section in the Orddar app. From there, you can edit your personal details, address, and payment methods.</p>

                                                <b>36. What should I do if I forget my Orddar account password?</b>
                                                <p>If you forget your password, click on the "Forgot Password" link on the login screen. Follow the instructions to reset your password via email or SMS.</p>

                                                <h6 class='mt-3'>Restaurant and Food Queries</h6>

                                                <b>37. How do I find a specific restaurant on the Orddar app?</b>
                                                <p>Use the search bar in the app to enter the name of the restaurant you are looking for. You can also browse by category or cuisine.</p>

                                                <b>38. Can I contact the restaurant directly through the app?</b>
                                                <p>Yes, contact details for the restaurant are provided in the app. Use these details to contact the restaurant directly for any specific queries.</p>

                                                <b>39. What should I do if I have dietary restrictions or allergies?</b>
                                                <p>Please mention any dietary restrictions or allergies in the special instructions section while placing your order. You can also contact the restaurant directly for more information.</p>

                                                <b>40. How do I know if a restaurant delivers to my location?</b>
                                                <p>The app will display only those restaurants that deliver to your entered location. Ensure your delivery address is correct to see the available options.</p>

                                                <b>41. Can I see the menu and prices before placing an order?</b>
                                                <p>Yes, you can view the complete menu and prices for each restaurant before placing an order.</p>

                                                <b>42. How do I know if a restaurant is open for orders?</b>
                                                <p>The availability of restaurants is displayed in the app. You can see the operating hours on the restaurant's menu page, and the app will indicate if a restaurant is currently open or closed.</p>

                                                <h6 class='mt-3'>Technical Support</h6>

                                                <b>43. What should I do if the app is not working properly?</b>
                                                <p>First, try restarting the app or your device. If the issue persists, contact Orddar support through the app or by emailing support@orddar.com.</p>

                                                <b>44. How do I report a bug or technical issue with the app?</b>
                                                <p>Report any bugs or technical issues through the "Help & Support" section in the app or by emailing support@orddar.com with a detailed description of the problem.</p>

                                                <b>45. Is Orddar available on both Android and iOS devices?</b>
                                                <p>Yes, Orddar is available for download on both Android and iOS devices.</p>

                                                <b>46. How do I update the Orddar app to the latest version?</b>
                                                <p>Check the App Store or Google Play Store for updates. If an update is available, you will see an option to update the app.</p>

                                                <b>47. What should I do if I experience a technical issue with the Orddar app?</b>
                                                <p>If you encounter any technical issues with the app, please report them through the "Help & Support" section in the app or email us at support@orddar.com.</p>

                                                <h6 class='mt-3'>Promotions and Discounts</h6>

                                                <b>48. How do I apply a promo code or discount to my order?</b>
                                                <p>Enter the promo code in the designated field during checkout. The discount will be applied to your order if the code is valid.</p>

                                                <b>49. Are there any ongoing promotions or discounts on Orddar?</b>
                                                <p>Check the "Offers" section in the app for current promotions and discounts.</p>

                                                <b>50. How do I know if a promo code is valid?</b>
                                                <p>Promo codes will be validated at checkout. If a code is invalid or expired, you will receive a notification.</p>

                                                <b>51. Can I use multiple promo codes on a single order?</b>
                                                <p>Typically, only one promo code can be used per order. Please check the specific terms and conditions of each promo code.</p>

                                                <h6 class='mt-3'>General Inquiries</h6>

                                                <b>52. What areas does Orddar deliver to?</b>
                                                <p>Delivery areas are determined by the participating restaurants. Enter your delivery address in the app to see the available restaurants that deliver to your location.</p>

                                                <b>53. Can I use the Orddar app if I am outside of India?</b>
                                                <p>Currently, Orddar services are only available within India.</p>

                                                <b>54. How do I provide feedback or suggestions about the app?</b>
                                                <p>You can provide feedback or suggestions through the "Feedback" section in the app or by emailing support@orddar.com.</p>

                                                <b>55. Does Orddar have a customer support phone number?</b>
                                                <p>At this time, customer support is available via email at support@orddar.com. You can also reach out through the "Help & Support" section in the app.</p>

                                                <b>56. How do I stay updated with new features and updates from Orddar?</b>
                                                <p>Stay updated by following Orddar on social media, enabling app notifications, and regularly checking the "Notifications" section in the app.</p>

                                                <b>57. How do I contact Orddar support for help?</b>
                                                <p>For any assistance, you can contact Orddar support by emailing us at support@orddar.com or through the "Help & Support" section in the app.</p>

                                                <b>58. How can I find the contact details of the restaurant?</b>
                                                <p>Contact details for the restaurant are available in the app under the restaurant's profile or order details section.</p>

                                                <b>59. How do I report an issue with my order?</b>
                                                <p>If you encounter an issue with your order, such as missing items or incorrect delivery, please contact the restaurant directly. You can also report the issue to Orddar support for further assistance.</p>

                                                <b>60. What should I do if the restaurant is not responding to my delivery queries?</b>
                                                <p>If the restaurant is not responding, please reach out to Orddar support through the app or by emailing support@orddar.com for assistance.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Layout>
        </div>
    )
}

export default Policies