import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Contact from './Pages/Contact';
import HomePage from './Pages/HomePage';
import Policies from './Pages/Policies';
import About from './Pages/About';


function App() {
  return (
    <div>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          
          <Route path="/about" element={<About />} />

          <Route path="/policies" element={<Policies />} />

          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
