import React from 'react';
import '../../css/main.css';
import Head from './Head';

const Header = () => {
  return (
   <Head/>
  );
};

export default Header;
