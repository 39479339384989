import React from 'react'
import '../css/main.css'
import footerlogo from '../Images/revzcon logo-01.png'
function Footer() {
    return (
        <div>



            <footer id="footer" class="footer " >

                <div class="">
                    <div class="row gy-3 footerRow" style={{ minHeight: "200px" }}>

                        <div class="col-lg-3 col-md-3 footer-links">
                            <img src={footerlogo} alt="" style={{ height: "100px", width: "300px", marginTop: "-30px", marginLeft: "-20px" }} />
                            <p className='mt-4'>Welcome to Orddar, the food, grocery, and bakery delivery application operated by REVZCON
                                NETWORK PRIVATE LIMITED .</p>
                            <h4>Follow Us</h4>
                            <div class="social-links d-flex">
                                <a href="#" class="twitter"><i class="fa-brands fa-x-twitter"></i></a>
                                <a href="#" class="facebook"><i class="fa-brands fa-facebook"></i></a>
                                <a href="#" class="instagram"><i class="fa-brands fa-instagram"></i></a>
                                <a href="#" class="linkedin"><i class="fa-brands fa-linkedin"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 d-flex">
                           
                            <div class="orddar">
                               
                            <h4>Orddar</h4>
                            <a href="/about">Who we are</a>
                            <br></br>
                            <a href="/policies">Terms and Conditions</a>
                            <br></br>
                            <a href="/policies">Refunds Policy</a>
                            <br></br>
                            <a href="/policies">Frequently Asked Questions</a>
                            <br></br>
                            <a href="/Contact">Contact Us</a>
                            </div>

                        </div>


                        <div class="col-lg-3 col-md-6 d-flex">
                            
                            <div class="address">
                                <h4><i class="fa-solid fa-envelope mt-1 me-2" style={{ color: "orange" }}></i> Contact</h4>
                                <p>
                                
                                    <strong>Email:</strong> <span>contact@orddar.com</span><br></br>
                                </p>
                            </div>

                        </div>


                        <div class="col-lg-3 col-md-6">
                            <div class="footer__single">
                                <div class="footer__single-intro">
                                    <h5>Subscribe our newsletter</h5>
                                </div>
                                <div class="footer__single-content">
                                    <p>Welcome to our food delivery app. We specialize in bringing delicious meals, fresh groceries, and tasty bakery items right to your doorstep.</p>
                                    <br />
                                    <p>Join our community of food lovers and stay updated on the latest culinary delights. Enter your email below to get started!</p>
                                    <div class="footer__single-form">
                                        <form action="#" method="post">
                                            <div class="input-email d-flex">
                                                <input type="email" name="subscribe-news" id="subscribeNews" placeholder="Enter Your Email" required className='form-control' />
                                                <button type="submit" class="btn subscribe">
                                                    <i class="fa-sharp fa-solid fa-paper-plane" style={{ color: "orange" }} ></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="mt-4 container copyright w-100 text-center  d-flex flex-column justify-content-center align-items-center foootercopyright mt-4"  >


                    <p>© <span>Copyright</span> <strong class="px-1 sitename">Orddar</strong><span>| All Rights Reserved</span></p>

                    <div class="credits">

                        Designed by <a href="https://www.weberfox.com/">WeberFox Technologies Pvt. Ltd.</a>

                    </div>
                </div>


            </footer>
            <a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
        </div>
    )
}

export default Footer