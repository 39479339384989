import React from 'react'
import Layout from '../components/Layout/Layout'
import Footer from '../components/Footer'
import food from '../img/about-2.jpg'
function Contact() {
    return (
        <div>

            <Layout>




                <section id="contact" class="contact section bg-tertiary">


                    <div class="section-title container-fluid mt-2" data-aos="fade-up">
                        <h2>Contact</h2>
                        <p><span>Need Help?</span> <span class="description-title">Contact Us</span></p>
                    </div>

                    <div class="container container-fluid contactfrom1" data-aos="fade-up" data-aos-delay="100">



                        <div class="row gy-4 ">




                            <div class="col-md-3">
                                <div class="info-item d-flex align-items-center flex-column justify-content-center" data-aos="fade-up" data-aos-delay="400">
                                    <div class="social-links d-flex justify-content-center align-items-center">
                                        <a href="#" class="twitter d-flex justify-content-center align-items-center"><i style={{ backgroundColor: "orange", height: "50px", width: "50px", borderRadius: "50%", color: "white", }} class="fa-solid fa-envelope d-flex justify-content-center align-items-center "></i></a>
                                    </div>
                                    <br></br>
                                    <div className='d-flex align-items-center flex-column justify-content-center'>
                                        <h3>Email Us</h3>
                                        <p>contact@orddar.com </p>
                                    </div>


                                </div>

                            
                            </div>
                            <div className='col-md-9' style={{ marginTop: "-10px" }}>
                                <form action="forms/contact.php" method="post" class="php-email-form" data-aos="fade-up" data-aos-delay="600">
                                    <div class="row gy-4">

                                        <div class="col-md-6">
                                            <input type="text" name="name" class="form-control" placeholder="Your Name" required="" />
                                        </div>

                                        <div class="col-md-6 ">
                                            <input type="email" class="form-control" name="email" placeholder="Your Email" required="" />
                                        </div>

                                        <div class="col-md-12">
                                            <input type="text" class="form-control" name="subject" placeholder="Subject" required="" />
                                        </div>

                                        <div class="col-md-12">
                                            <textarea class="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
                                        </div>

                                        <div class="col-md-12 text-center">
                                            <div class="loading">Loading</div>
                                            <div class="error-message"></div>
                                            <div class="sent-message">Your message has been sent. Thank you!</div>

                                            <button type="submit">Send Message</button>
                                        </div>

                                    </div>
                                </form>
                            </div>


                        </div>



                    </div>

                </section>
            </Layout>
            <Footer />
        </div>
    )
}

export default Contact