import React from 'react'
import '../css/main.css'
import restaurant1 from '../Images/restaurant image 1.png'
import restuarnt2 from '../Images/restaurant image 2.png'
import Layout from '../components/Layout/Layout'
import Footer from '../components/Footer'
import bg from '../img/stats-bg.jpg'
function About() {
    return (
        <div>
            <Layout>
        


      
                <div className='  d-flex justify-content-center align-items-center flex-column'  style={{marginTop:"70px"}}>
                    <div class="section-title">
                        <h2>About Us <br></br></h2>
                        <p><span>Learn More</span> <span class="description-title">About Us</span></p>
                    </div>
                    <div className="row w-100 aboutRow">
                        <div className="col-lg-5 mt-1 aboutCol">
                            <img src={restaurant1} alt="" className='w-100 ' />

                            <div className='d-flex justify-content-center align-items-center flex-column aboutCol1'>
                                <div class="book-a-table">
                                    <h3>Contact Us</h3>
                                    <p>+1 5589 55488 55</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-7 mt-1  aboutCol2">
                            <div class="content ps-0 ps-lg-5">
                                <p class="fst-italic">
                                    Experience the best culinary delights, whether dining in, ordering from restaurants, or picking up groceries and bakery items.
                                </p>
                                <ul style={{ listStyle: "none" }}>
                                    <li><i class="fa-solid fa-check" style={{ color: "orange" }}></i> <span class='ms-2'>Conveniently order food from your favorite restaurants.</span></li>
                                    <li><i class="fa-solid fa-check" style={{ color: "orange" }}></i> <span class='ms-2'>Enjoy quick and easy grocery shopping.</span></li>
                                    <li><i class="fa-solid fa-check" style={{ color: "orange" }}></i> <span class='ms-2'>Savor delicious bakery treats delivered to your doorstep.</span></li>
                                </ul>
                                <p>
                                    With Orddar, you get the best of dining, groceries, and bakery items all in one place. Enjoy seamless ordering and exceptional service every time.
                                </p>
                                <div class="position-relative mt-4">
                                    <img src={restuarnt2} class="img-fluid" alt="" className='w-100' />
                                    <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" class="glightbox play-btn"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="book-a-table" class="book-a-table section">


                    <div class=" section-title" data-aos="fade-up">
                        <h2>Book A Table</h2>
                        <p><span>Book Your</span> <span class="description-title">Stay With Us<br></br></span></p>
                    </div>

                    <div class="bookTable">

                        <div class="row g-0" data-aos="fade-up" data-aos-delay="100">

                            <div class="col-lg-4 reservation-img" style={{ backgroundImage: "url('https://images.pexels.com/photos/262047/pexels-photo-262047.jpeg?auto=compress&cs=tinysrgb&w=600)" }}></div>





                            <div class="col-lg-8 d-flex align-items-center reservation-form-bg" data-aos="fade-up" data-aos-delay="200">
                                <form action="forms/book-a-table.php" method="post" role="form" class="php-email-form">
                                    <div class="row gy-4">
                                        <div class="col-lg-4 col-md-6">
                                            <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required="" />
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required="" />
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <input type="text" class="form-control" name="phone" id="phone" placeholder="Your Phone" required="" />
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <input type="date" name="date" class="form-control" id="date" placeholder="Date" required="" />
                                        </div>

                                        <div class="col-lg-4 col-md-6">
                                            <input type="time" class="form-control" name="time" id="time" placeholder="Time" required="" />
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <input type="number" class="form-control" name="people" id="people" placeholder="# of people"
                                                required="" />
                                        </div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <textarea class="form-control" name="message" rows="5" placeholder="Message"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <div class="loading">Loading</div>
                                        <div class="error-message"></div>
                                        <div class="sent-message">Your booking request was sent. We will call back or send an Email to confirm
                                            your reservation. Thank you!</div>
                                    </div>
                                    <div class="text-center"><button className='btn mt-3 btn-lg btnHome rounded-5' style={{ fontSize: "17px" }}>Book a Table</button></div>

                                </form>
                            </div>

                        </div>

                    </div>
            
                </section>
            </Layout>
            <Footer />

        </div>
    )
}

export default About