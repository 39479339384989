import React from 'react'
import '../css/main.css'
import Layout from '../components/Layout/Layout'
import homeburger from '../Images/home burger.png'
import Ellipse from '../Images/image.png'
import restaurant1 from '../Images/restaurant image 1.png'
import restuarnt2 from '../Images/restaurant image 2.png'
import ChickenFry from '../Images/Chicken fry 2.png'
import chickenCurry from '../Images/chicken curry 1.png'
import burger from '../Images/home burger.png'
import beef from '../Images/beef-.png'
import porota from '../Images/porota 2.png'
import fish from '../Images/fish.png'
import restaurant from '../Images/restaurnt_orange.png'
import bakery from '../Images/bakery_orange.png'
import grocery from '../Images/grocery_orange.png'
import Footer from '../components/Footer'
import cutlet from '../Images/cutlet.png'
import milkshake from '../Images/milk_shake.png'
import mohito from '../Images/mojito.png'
import puffs from '../Images/puffs.png'
import cookies from '../Images/cookies.png'
import smoothies from '../Images/smoothies.png'
import meat from '../Images/meats.png'
import orange from '../Images/orange.png'
import vegetables from '../Images/Vegitables.png'
import apple from '../Images/apple.png'
import mango from '../Images/mango.png'
import fruits from '../Images/fruits.png'
import Header from '../components/Layout/Header'

function HomePage() {
    return (
        <div class="">

        <Header/>

       
                <section id="hero" class=" section">

                    <div class="">
                        <div class="row gy-4 justify-content-center justify-content-lg-between">

                        <div class="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
                                <div className="Ellipse"><img src={Ellipse} style={{ marginTop: "-70px", marginLeft: "20px" }} /></div>
                                <img src={homeburger} class="img-fluid animated homeBurger" alt="" />
                            </div>
                            <div class="container col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center">
                                <h1 data-aos="fade-up">Savor the Best<br></br>Healthy and Delicious Food</h1>
                                <p>Discover a world of culinary delights with Orddar, your go-to app for dining in, ordering from restaurants, grocery shopping, and bakery treats.</p>
                                <div class="d-flex" data-aos="fade-up" data-aos-delay="200">
                                    <a href="#book-a-table" class="btn-get-started">Book a Table</a>
                                    <a href="#" class="glightbox btn-watch-video d-flex align-items-center">
                                        <i class="bi bi-play-circle"></i><span>Watch Video</span>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </section>



                <div className='aboutus  d-flex justify-content-center align-items-center flex-column'  >
                    <div class="section-title">
                        <h2>About Us <br></br></h2>
                        <p><span>Learn More</span> <span class="description-title">About Us</span></p>
                    </div>
                    <div className="row w-100 aboutRow">
                        <div className="col-lg-5 mt-1 aboutCol">
                            <img src={restaurant1} alt="" className='w-100 ' />

                            <div className='d-flex justify-content-center align-items-center flex-column aboutCol1'>
                                <div class="book-a-table text-center">
                                    <h3>Email Us</h3>
                                    <p>contact@orddar.com</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-7 mt-1  aboutCol2">
                            <div class="content ps-0 ps-lg-5">
                                <p class="fst-italic">
                                    Experience the best culinary delights, whether dining in, ordering from restaurants, or picking up groceries and bakery items.
                                </p>
                                <ul style={{ listStyle: "none" }}>
                                    <li><i class="fa-solid fa-check" style={{ color: "orange" }}></i> <span class='ms-2'>Conveniently order food from your favorite restaurants.</span></li>
                                    <li><i class="fa-solid fa-check" style={{ color: "orange" }}></i> <span class='ms-2'>Enjoy quick and easy grocery shopping.</span></li>
                                    <li><i class="fa-solid fa-check" style={{ color: "orange" }}></i> <span class='ms-2'>Savor delicious bakery treats delivered to your doorstep.</span></li>
                                </ul>
                                <p>
                                    With Orddar, you get the best of dining, groceries, and bakery items all in one place. Enjoy seamless ordering and exceptional service every time.
                                </p>
                                <div class="position-relative mt-4">
                                    <img src={restuarnt2} class="img-fluid" alt="" className='w-100' />
                                    <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" class="glightbox play-btn"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="why-us" class="why-us section" style={{ minHeight: "55vh" }}>
                    <div className="row w-100 whyUsRow">
                        <div className="col-lg-4">
                            <div class="why-box">
                                <h3>Why Choose Orddar</h3>
                                <p>
                                    Orddar offers a seamless and convenient way to enjoy delicious meals, bakery treats, and grocery shopping. Our app provides exceptional service and a wide variety of options to cater to all your needs.
                                    Whether you are craving a gourmet meal from a top restaurant or fresh bakery items, Orddar ensures timely delivery and quality service every time.
                                </p>

                                <div class="text-center">
                                    <a href="#" class="more-btn"><span>Learn More</span> <i class="bi bi-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-4 mt-1">
                                    <div class="icon-box d-flex flex-column justify-content-center align-items-center">

                                        <i > <img src={restaurant}
                                            class="menu-img img-fluid" alt="" /></i>
                                        <h4>Restaurant</h4>
                                        <p>Order from your favorite restaurants and enjoy a variety of cuisines delivered right to your doorstep.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-1">
                                    <div class="icon-box d-flex flex-column justify-content-center align-items-center">
                                        <i > <img src={bakery}
                                            class="menu-img img-fluid" alt="" /></i>
                                        <h4>Bakery</h4>
                                        <p>Indulge in freshly baked goods and delicious pastries from top bakeries in your area. Treat yourself to the finest cakes, bread, and desserts.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-1">
                                    <div class="icon-box d-flex flex-column justify-content-center align-items-center">
                                        <i > <img src={grocery}
                                            class="menu-img img-fluid" alt="" /></i>
                                        <h4>Grocery</h4>
                                        <p>Shop for groceries with ease and get fresh produce, dairy, and other essentials delivered to your home.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </section>
                <div id='menu' className='aboutus menu  d-flex justify-content-center align-items-center flex-column'  >
                    <div class="section-title">
                        <h2>Our Menu</h2>
                        <p><span>Check Our</span> <span class="description-title">Yummy Menu</span></p>
                    </div>
                    <div>
                        <ul class="nav nav-tabs d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">

                            <li class="nav-item">
                                <a class="nav-link active show" data-bs-toggle="tab" data-bs-target="#menu-starters">
                                    <h4>Restaurant</h4>
                                </a>
                            </li>


                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#menu-lunch">
                                    <h4>Bakery</h4>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#menu-dinner">
                                    <h4>Grocery</h4>
                                </a>
                            </li>

                        </ul>
                        <div class="tab-content" data-aos="fade-up" data-aos-delay="200">

                            <div class="tab-pane fade active show" id="menu-starters">

                                <div class="tab-header text-center">
                                    <p>Menu</p>
                                    <h3>Restaurant</h3>
                                </div>
                                <div class="row gy-5">

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={fish}
                                            class="menu-img img-fluid" alt="" /></a>
                                        <h4>Grilled Fish</h4>
                                        <p class="ingredients">
                                            Freshly caught fish, marinated with herbs and spices.
                                        </p>
                                        <p class="price">
                                            ₹230
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={chickenCurry}
                                            class="menu-img img-fluid" alt="" /></a>
                                        <h4>Chicken Curry</h4>
                                        <p class="ingredients">
                                            Tender chicken cooked in a rich , served with rice.
                                        </p>
                                        <p class="price">
                                            ₹250
                                        </p>
                                    </div>
                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={beef}
                                            class="menu-img img-fluid" alt="" /></a>
                                        <h4>Beef Steak</h4>
                                        <p class="ingredients">
                                            Juicy beef steak , served with mashed potatoes.
                                        </p>
                                        <p class="price">
                                            ₹350
                                        </p>
                                    </div>
                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={porota}
                                            class="menu-img img-fluid" alt="" /></a>
                                        <h4>Porota</h4>
                                        <p class="ingredients">
                                            Flaky and crispy porota bread, perfect as a side or a snack.
                                        </p>
                                        <p class="price">

                                            ₹20
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={burger}
                                            class="menu-img img-fluid" alt="" /></a>
                                        <h4>Classic Burger</h4>
                                        <p class="ingredients">
                                            Juicy beef patty with lettuce, served in a toasted bun.
                                        </p>
                                        <p class="price">

                                            ₹180
                                        </p>
                                    </div>
                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={ChickenFry}
                                            class="menu-img img-fluid" alt="" /></a>
                                        <h4>Fried Chicken</h4>
                                        <p class="ingredients">
                                            Crispy fried chicken , served with a side of fries.
                                        </p>
                                        <p class="price">
                                            ₹220
                                        </p>
                                    </div>
                                </div>

                            </div>


                        </div>


                        <div class="tab-content" data-aos="fade-up" data-aos-delay="200">
                            <div class="tab-pane fade" id="menu-lunch">

                                <div class="tab-header text-center">
                                    <p>Menu</p>
                                    <h3>Bakery</h3>
                                </div>
                                <div class="row gy-5">

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={cutlet}
                                            class="menu-img img-fluid" alt="Cutlet" /></a>
                                        <h4>Vegetable Cutlet</h4>
                                        <p class="ingredients">
                                            Mixed vegetables and spices, breaded and fried to perfection.
                                        </p>
                                        <p class="price">
                                            ₹35
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={smoothies}
                                            class="menu-img img-fluid" alt="Smoothies" /></a>
                                        <h4>Fruit Smoothies</h4>
                                        <p class="ingredients">
                                            A blend of fresh fruits, yogurt, and honey.
                                        </p>
                                        <p class="price">
                                            ₹125
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={milkshake}
                                            class="menu-img img-fluid" alt="Milkshake" /></a>
                                        <h4>Chocolate Milkshake</h4>
                                        <p class="ingredients">
                                            Rich chocolate ice cream blended with milk.
                                        </p>
                                        <p class="price">
                                            ₹120
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={cookies}
                                            class="menu-img img-fluid" alt="Cookies" /></a>
                                        <h4>Chocolate Chip Cookies</h4>
                                        <p class="ingredients">
                                            Freshly baked cookies with rich chocolate chips.
                                        </p>
                                        <p class="price">
                                            ₹100
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={mohito}
                                            class="menu-img img-fluid" alt="Mohito" /></a>
                                        <h4>Classic Mojito</h4>
                                        <p class="ingredients">
                                            A refreshing mix of mint, lime, and soda.
                                        </p>
                                        <p class="price">
                                            ₹70
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={puffs}
                                            class="menu-img img-fluid" alt="Puffs" /></a>
                                        <h4>Chicken Puffs</h4>
                                        <p class="ingredients">
                                            Flaky pastry filled with spiced chicken.
                                        </p>
                                        <p class="price">

                                            ₹20
                                        </p>
                                    </div>

                                </div>




                            </div>
                        </div>


                        <div class="tab-content" data-aos="fade-up" data-aos-delay="200">
                            <div class="tab-pane fade" id="menu-dinner">

                                <div class="tab-header text-center">
                                    <p>Menu</p>
                                    <h3>Grocery</h3>
                                </div>
                                <div class="row gy-5">

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={meat}
                                            class="menu-img img-fluid" alt="Meat" /></a>
                                        <h4>Meat</h4>
                                        <p class="ingredients">
                                            Fresh and high-quality meat for your cooking needs.
                                        </p>
                                        <p class="price">
                                            ₹120.95
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={orange}
                                            class="menu-img img-fluid" alt="Orange" /></a>
                                        <h4>Oranges</h4>
                                        <p class="ingredients">
                                            Juicy and sweet oranges, perfect for a healthy snack.
                                        </p>
                                        <p class="price">
                                            ₹50.95 per kg
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={apple}
                                            class="menu-img img-fluid" alt="Apple" /></a>
                                        <h4>Apples</h4>
                                        <p class="ingredients">
                                            Crisp and fresh apples, great for a quick bite.
                                        </p>
                                        <p class="price">
                                            ₹85.95 per kg
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={mango}
                                            class="menu-img img-fluid" alt="Mango" /></a>
                                        <h4>Mangoes</h4>
                                        <p class="ingredients">
                                            Sweet and ripe mangoes, perfect for desserts.
                                        </p>
                                        <p class="price">
                                            ₹30.95 per piece
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={fruits}
                                            class="menu-img img-fluid" alt="Fruits" /></a>
                                        <h4>Mixed Fruits</h4>
                                        <p class="ingredients">
                                            A selection of the freshest fruits available.
                                        </p>
                                        <p class="price">
                                            ₹80.95 per basket
                                        </p>
                                    </div>

                                    <div class="col-lg-4 menu-item">
                                        <a class="glightbox"><img src={vegetables}
                                            class="menu-img img-fluid" alt="Vegetables" /></a>
                                        <h4>Mixed Vegetables</h4>
                                        <p class="ingredients">
                                            A variety of fresh and organic vegetables.
                                        </p>
                                        <p class="price">
                                            ₹90.95 per kg
                                        </p>
                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>

                </div>
                <section id="book-a-table" class="book-a-table section">


                    <div class=" section-title" data-aos="fade-up">
                        <h2>Book A Table</h2>
                        <p><span>Book Your</span> <span class="description-title">Stay With Us<br></br></span></p>
                    </div>

                    <div class="bookTable">

                        <div class="row g-0" data-aos="fade-up" data-aos-delay="100">

                            <div class="col-lg-4 reservation-img" style={{ backgroundImage: "url('https://images.pexels.com/photos/262047/pexels-photo-262047.jpeg?auto=compress&cs=tinysrgb&w=600)" }}></div>

                            <div class="col-lg-8 d-flex align-items-center reservation-form-bg" data-aos="fade-up" data-aos-delay="200">
                                <form action="forms/book-a-table.php" method="post" role="form" class="php-email-form">
                                    <div class="row gy-4">
                                        <div class="col-lg-4 col-md-6">
                                            <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required="" />
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required="" />
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <input type="text" class="form-control" name="phone" id="phone" placeholder="Your Phone" required="" />
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <input type="date" name="date" class="form-control" id="date" placeholder="Date" required="" />
                                        </div>

                                        <div class="col-lg-4 col-md-6">
                                            <input type="time" class="form-control" name="time" id="time" placeholder="Time" required="" />
                                        </div>
                                        <div class="col-lg-4 col-md-6">
                                            <input type="number" class="form-control" name="people" id="people" placeholder="# of people"
                                                required="" />
                                        </div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <textarea class="form-control" name="message" rows="5" placeholder="Message"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <div class="loading">Loading</div>
                                        <div class="error-message"></div>
                                        <div class="sent-message">Your booking request was sent. We will call back or send an Email to confirm
                                            your reservation. Thank you!</div>
                                    </div>
                                    <div class="text-center"><button type="submit">Book a Table</button></div>

                                </form>
                            </div>

                        </div>

                    </div>

                </section>

            
            <Footer />
        </div>
    )
}

export default HomePage